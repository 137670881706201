import React from "react"
import "./contact.css"
import {MdOutlineMail} from "react-icons/md"
import {BsLinkedin} from "react-icons/bs"
import { useRef } from 'react';
import emailjs from 'emailjs-com'


const Contact = () => {
    const form = useRef();

    const sendEmail = (e) => {
        e.preventDefault();
    
        emailjs.sendForm('service_9efskt7', 'template_2fkx3p2', form.current, 'mi9826vamjvL0M_2-')
        .then((result) => {
            console.log(result.text);
        }, (error) => {
            console.log(error.text);
        });

        e.target.reset()
    };

    return (
        <section id="contact">
            <h5>Get In Touch</h5>
            <h2>Contact Me</h2>

            <div className="container contact__container">
                <div className="contact__options">
                    <article className="contact__option">
                        <MdOutlineMail className="contact__option-icon"/>
                        <h4>Email</h4>
                        <h5>youssefchouay30@gmail.com</h5>
                        <a href="mailto:youssefchouay30@gmail.com" target="_blank">Send a message</a>
                    </article>

                    <article className="contact__option">
                        <BsLinkedin className="contact__option-icon"/>
                        <h4>Linkedin</h4>
                        <h5>Youssef Chouay</h5>
                        <a href="https://www.linkedin.com/in/youssef-chouay/" target="_blank">Connect with me</a>
                    </article>
                </div>
                <form ref={form} onSubmit={sendEmail}>
                    <input type="text" name="name" placeholder="Your Full Name" required/>
                    <input type="text" name="email" placeholder="Your Email" required />
                    <textarea name="message" rows="7" placeholder="Your Message" required></textarea>
                    <button type="submit" className="btn btn-primary">Send Message</button>
                </form>
            </div>
        </section>
    )
}

export default Contact